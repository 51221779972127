'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.contacts.factory:ContactDataContextManager

 # @description

###
angular
  .module 'mundoAdmin.contacts'
  .factory 'ContactDataContextManager', [
    'MundoDefaultManager'
    (MundoDefaultManager) ->
      ContactDataContextManagerBase = new MundoDefaultManager()
      ContactDataContextManagerBase.setUrl('contacts/data/contexts')

      ContactDataContextManagerBase
  ]
